import React, { useEffect, useState, forwardRef } from "react";
import Select from "react-select";
import { api, header_token } from "../../config";
import Axios from "axios";
import swal from "sweetalert";

export const BranchEditArea = forwardRef((props, ref) => {
  const [area, setarea] = useState([{ area: "", new: true }]);
  const [option_area, setoption_area] = useState([]);
  const [trig, settrig] = useState(false);

  const getOptionArea = async () => {
    try {
      let { data } = await Axios.get(`${api}/area`);
      // console.log("areaxxx", area);
      let tmp = [];

      area.forEach((v) => {
        if (v.area) {
          tmp.push(v.area);
        }
      });

      data
        .filter((e) => e.branch_id === "")
        .forEach((value) => {
          tmp.push({ value: value.id, label: value.name_th });
        });
      // console.log("tmp", tmp);

      setoption_area(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const addAreaToBranch = async (area_data) => {
    try {
      let res = await Axios.post(
        `${api}/admin/branch/area/`,
        area_data,
        header_token
      );
      // console.log(res);
      if (res.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error.response);
      if (error.response.status === 422) {
        swal("พื้นที่ซ้ำ !!", {
          icon: "error",
        });
      }
      return false;
    }
  };

  const deleteAreaFromBranch = async (area_id) => {
    try {
      let res = await Axios.delete(
        `${api}/admin/branch/area/${area_id}`,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error.response);
      return false;
    }
  };

  useEffect(() => {
    getOptionArea();
  }, [trig]);

  useEffect(() => {
    if (Object.keys(props.parentData).length > 0) {
      let _area = [];
      props.parentData.forEach((v, idx) => {
        _area.push({ area: { label: v.name_th, value: v.id } });
      });

      setarea(_area);
      settrig(!trig);
    }
  }, [props.parentData]);

  return (
    <div>
      <div className="form-group col-12">
        {area.map((value, index) => {
          return (
            <div key={index}>
              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="area">พื้นที่ {index + 1}</label>
                </div>
                <div className="col-lg-5">
                  <Select
                    value={value.area}
                    onChange={(e) => {
                      let tmp = [...area];

                      tmp[index].area = e;
                      setarea(tmp);
                    }}
                    options={option_area}
                    placeholder={"เลือกพื้นที่.."}
                  />
                </div>

                <div className="col-lg-4">
                  {value.new ? (
                    <button
                      type="button"
                      className="btn btn-save  ml-2"
                      onClick={async () => {
                        if (value.area.value) {
                          let is_success = await addAreaToBranch({
                            areaId: value.area.value,
                            branchId: props.branchId,
                          });

                          if (is_success) {
                            swal("เพิ่มเสร็จสิ้น !!", {
                              icon: "success",
                            });
                            let tmp = [...area];
                            tmp[index].new = false;
                            setarea(tmp);
                          }
                        }
                      }}
                    >
                      <i className="fa fa-save"></i>
                    </button>
                  ) : null}
                  {area.length >= 1 && !value.new ? (
                    <button
                      type="button"
                      className="btn btn-danger  ml-2"
                      onClick={async () => {
                        swal({
                          title: `ต้องการลบพื้นที่ที่เลือกหรือไม่ ?`,
                          confirmButtonText: "ลบ",
                          cancelButtonText: "ยกเลิก",
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then(async (willDelete) => {
                          if (willDelete) {
                            let is_success = await deleteAreaFromBranch(
                              value.area.value
                            );

                            if (is_success) {
                              swal("ลบเสร็จสิ้น !!", {
                                icon: "success",
                              });

                              let tmp = [...area];
                              tmp.splice(index, 1);
                              if (tmp.length === 0) {
                                tmp.push({ area: "", new: true });
                              }
                              setarea(tmp);
                            } else {
                              swal("เกิดข้อผิดพลาด !!", {
                                icon: "error",
                              });
                            }
                          }
                        });
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  ) : null}

                  {area.length === index + 1 ? (
                    <button
                      type="button"
                      className="btn btn-primary  ml-2"
                      onClick={() => {
                        let tmp = [...area];

                        tmp.push({ area: "", new: true });
                        setarea(tmp);
                      }}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="row">
        <div className="col-10"></div>
        <div className="col">
          <div className="row">
            <a
              href="#"
              className="btn btn-primary  mr-2  mt-2 "
              onClick={() => {
                // props.callbackArea(area);
                props.nextRef.current.click();
              }}
            >
              ถัดไป
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});
