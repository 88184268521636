import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import moment from "moment";
import { api, img_url, header_token, selectStyle } from "../../config";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import swal from "sweetalert";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import "moment/locale/th";
import { RestaurantHead } from "./RestaurantHead";
import { ExportRestaurantData } from "./ExportRestaurantData";

const action_option = [{ value: 0, label: "ลบ" }];

export const RestaurantAll = () => {
  const checkAllRef = useRef(null);
  const [loading, setloading] = useState(false);

  const [img, setimg] = useState("");
  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [total_page, settotal_page] = useState(0);
  const [limit] = useState(10);
  const [total_count, settotal_count] = useState(0);

  const [filter_name, setfilter_name] = useState("");
  const [filter_area, setfilter_area] = useState({
    value: "",
    label: "เลือก..",
  });
  const [filter_province, setfilter_province] = useState({
    value: "",
    label: "เลือก..",
  });
  const [action, setaction] = useState({ value: "", label: "เลือก.." });

  const [option_area, setoption_area] = useState([]);
  const [option_province, setoption_province] = useState([]);

  const [bank_option, setbank_option] = useState([]);
  const [add_name, setadd_name] = useState("");
  const [bank, setbank] = useState("");
  const [rider_id, setrider_id] = useState("");
  const [credit_amount, setcredit_amount] = useState(1);
  const [note, setnote] = useState("");

  const [export_loading, setexport_loading] = useState(false);
  const [export_data, setexport_data] = useState([]);
  const [trigger, settrigger] = useState(false);

  const exportexcel = async () => {
    setexport_loading(true);

    let send_data = {
      area_id: filter_area.value,
      name_th: filter_name,
      province_id: filter_province.value,
      // status: [0],
    };
    try {
      let { data } = await Axios.post(
        `${api}/admin/restaurants/filter?page=${1}&limit=${99999999999}`,
        send_data,
        header_token
      );

      // console.log(data);

      let _export = [];
      if (data.restaurants) {
        data.restaurants.forEach((value, index) => {
          let categ = "";

          value.categories.map((v, idx) => {
            let comma = "";
            if (idx !== value.categories.length - 1) comma = ", ";
            categ += v.title + comma;

            return;
          });

          let st;
          switch (value.status) {
            case 0:
              st = "ระงับ";
              break;
            case 1:
              st = "ใช้งาน";
              break;
            case 2:
              st = "ไม่อนุมัติ";
              break;
            case 3:
              st = "รออนุมัติ";
              break;

            default:
              break;
          }
          _export.push({
            name: value.name_th,
            address: value.location.address,
            sub_district: value.location.sub_district.name_th,
            district: value.location.district.name_th,
            province: value.location.province.name_th,
            zip_code: value.location.sub_district.zip_code,
            email: value.email,
            phone_number: value.phone_numbers,
            area: value.area.name_th,
            menu: value.menu_count,
            credit: value.credit,
            status: st,
            food_type: categ,
            register: moment(value.register_date).format("DD/MM/YYYY"),
          });
        });
      }

      setexport_data(_export);
    } catch (error) {
      console.log(error.response);
    }

    setexport_loading(false);
  };

  const getBank = async () => {
    try {
      let { data } = await Axios.get(`${api}/bank`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setbank_option(tmp);
    } catch (error) {
      console.log(error.response);
    }
  };

  const getData = async () => {
    setloading(true);

    let send_data = {
      area_id: filter_area.value,
      name_th: filter_name,
      province_id: filter_province.value,
      // status: [0],
    };
    try {
      let { data } = await Axios.post(
        `${api}/admin/restaurants/filter?page=${current_page}&limit=${limit}`,
        send_data,
        header_token
      );

      // console.log(data);
      let page_count = Math.ceil(data.meta.total_count / limit);
      settotal_page(page_count);
      settotal_count(data.meta.total_count);

      let tmp = [];
      data.restaurants.forEach((value) => {
        value.checked = false;
        tmp.push(value);
      });
      // console.log(tmp);
      setdata(tmp);
    } catch (error) {
      console.log(error.response);
    }
    setloading(false);
  };

  const deleteRestaurant = async (restaurant_id) => {
    try {
      let res = await Axios.delete(
        `${api}/restaurants/${restaurant_id}`,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error.response);
      return false;
    }
  };

  const getOptionProvince = async () => {
    try {
      let { data } = await Axios.get(`${api}/province`);
      // console.log(data);
      let tmp = [];
      tmp.push({ value: "", label: "ทั้งหมด" });

      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_province(tmp);
    } catch (error) {
      console.log(error.response);
    }
  };

  const getOptionArea = async () => {
    try {
      let { data } = await Axios.get(`${api}/area`);
      // console.log(data);
      let tmp = [];
      tmp.push({ value: "", label: "ทั้งหมด" });

      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_area(tmp);
    } catch (error) {
      console.log(error.response);
    }
  };

  const addCredit = async (user_id) => {
    if (credit_amount && bank) {
      try {
        let send_data = {
          amount: credit_amount,
          bank_id: bank.value,
          picture: "",
          remark: note,
          transaction_date: `${moment().format("YYYY-MM-DD")} ${moment().format(
            "LTS"
          )}`,
        };

        // console.log("send_data", send_data);
        let res = await Axios.post(
          `${api}/admin/wallet/deposit/${user_id}?status=2`,
          send_data,
          header_token
        );

        // console.log(res);
        if (res.status === 201) {
          swal("เติมเครดิตเสร็จสิ้น !!", {
            icon: "success",
          });
          setcredit_amount(1);
          setnote("");
          setbank("");
          getData();
          return true;
        } else {
          return false;
        }
      } catch (error) {
        swal(`${error.response.data.message}`, {
          icon: "error",
          button: false,
        });
        return false;
      }
    } else {
      alert("ข้อมูลไม่ครบ");
    }
  };

  useEffect(() => {
    getData();
    getOptionProvince();
    getOptionArea();
    getBank();
  }, []);

  useEffect(() => {
    setcurrent_page(1);
    getData();
  }, [filter_area, filter_province, filter_name]);

  useEffect(() => {
    getData();
  }, [current_page]);

  return (
    <div>
      <RestaurantHead />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h4 className="mb-4 mt-2">
                    ทั้งหมด {total_count.toLocaleString()} รายการ
                  </h4>
                </div>

                <div className="form-row align-items-center mb-2 mr-2 ">
                  <div className=" mr-2" style={{ width: 200 }}>
                    <span className="tx-13 text-muted">ค้นหา</span>
                    <input
                      type="text"
                      className="form-control- global_filter"
                      id="global_filter"
                      placeholder="ชื่อ"
                      value={filter_name}
                      onChange={(e) => {
                        setfilter_name(e.target.value);
                      }}
                    />
                  </div>
                  <div className="ml-2 mr-3">
                    <div className="row">
                      <div style={{ width: 120 }}>
                        <span className="tx-13 text-muted">พื้นที่</span>
                        <Select
                          styles={selectStyle}
                          value={filter_area}
                          onChange={async (e) => {
                            setfilter_area(e);
                          }}
                          options={option_area}
                          placeholder={"พื้นที่..."}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ml-2 mr-3">
                    <div className="row">
                      <div style={{ width: 120 }}>
                        <span className="tx-13 text-muted">จังหวัด</span>
                        <Select
                          styles={selectStyle}
                          value={filter_province}
                          onChange={(e) => {
                            setfilter_province(e);
                          }}
                          options={option_province}
                          placeholder={"จังหวัด.."}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="ml-2 mr-3">
                    <div className="row">
                      <div style={{ width: 150 }}>
                        <span className="tx-13 text-muted">ดำเนินการ</span>
                        <Select
                          styles={selectStyle}
                          value={action}
                          onChange={(e) => {
                            setaction(e);
                          }}
                          options={action_option}
                          placeholder={"ดำเนินการ.."}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="ml-2 ">
                    <div>
                      <button
                        type="button"
                        id="search"
                        className="btn btn-primary "
                        style={{ marginTop: 23 }}
                        onClick={async () => {
                          let count = data.filter((e) => e.checked === true);
                          if (action.value === 0 && count.length > 0) {
                            swal({
                              title: `ต้องการลบเมนูที่เลือกหรือไม่ ?`,
                              confirmButtonText: "ลบ",
                              cancelButtonText: "ยกเลิก",
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then(async (willDelete) => {
                              if (willDelete) {
                                let sending = [];
                                data.forEach((value) => {
                                  if (value.checked === true) {
                                    sending.push(deleteRestaurant(value.id));
                                  }
                                });

                                let is_success = true;
                                await Promise.all(sending).then((res) => {
                                  // console.log(res);
                                  if (
                                    res.filter((e) => e === true).length ===
                                      0 ||
                                    res.length === 0
                                  )
                                    is_success = false;
                                });

                                if (is_success) {
                                  getData();

                                  swal("ลบเสร็จสิ้น !!", {
                                    icon: "success",
                                  });
                                } else {
                                  swal("เกิดข้อผิดพลาด !!", {
                                    icon: "error",
                                  });
                                }
                              }
                            });
                          } else {
                            alert("เลือกตัวดำเนินการ");
                          }
                        }}
                      >
                        บันทึก
                      </button>
                    </div>
                  </div>
                  <div className="ml-2 ">
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary mr-2"
                        style={{ marginTop: 23 }}
                        onClick={async () => {
                          if (!trigger && !export_loading) {
                            await exportexcel();
                            await settrigger(true);
                          }
                        }}
                      >
                        {export_loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : null}
                        {` `}Export
                      </button>
                      <ExportRestaurantData
                        data={export_data}
                        trigger={trigger}
                        settrigger={settrigger}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <LoadingMask loading={loading} text={"loading..."}>
                <div style={{ minHeight: 500 }}>
                  <div className="sticky-div">
                    <table id="dataTable" className="table sticky-table">
                      <thead>
                        <tr>
                          <th style={{ width: 30 }} className="sticky-head">
                            <input
                              type="checkbox"
                              className="selectAll "
                              name="selectAll"
                              defaultChecked={false}
                              ref={checkAllRef}
                              onClick={(e) => {
                                let tmp = [...data];
                                let _tmp = [];
                                tmp.forEach((value, index) => {
                                  value.checked = checkAllRef.current.checked;
                                  _tmp.push(value);
                                });

                                setdata(tmp);
                              }}
                            />
                          </th>
                          <th style={{ width: 100 }} className="sticky-head">
                            รูป
                          </th>
                          <th style={{ width: 80 }} className="sticky-head">
                            รหัส
                          </th>
                          <th style={{ width: 200 }} className="sticky-head">
                            ชื่อ
                          </th>
                          <th style={{ width: 250 }}>ที่อยู่</th>
                          <th style={{ width: 120 }}>ตำบล/แขวง</th>
                          <th style={{ width: 120 }}>อำเภอ/เขต</th>
                          <th style={{ width: 125 }}>จังหวัด</th>
                          <th style={{ width: 120 }}>รหัสไปรษณีย์</th>
                          <th style={{ width: 180 }}>เบอร์โทร</th>
                          <th style={{ width: 200 }}>อีเมล์</th>
                          <th style={{ width: 150 }}>พื้นที่</th>
                          <th style={{ width: 120 }}>เครดิต</th>
                          {/* <th style={{ width: 80 }}>ยอดขาย</th> */}
                          <th style={{ width: 80 }}>เมนู</th>
                          <th style={{ width: 100 }}>คะแนนรีวิว</th>
                          <th style={{ width: 180 }}>ประเภทอาหาร</th>
                          <th style={{ width: 120 }}>วันที่</th>
                          <th style={{ width: 80 }}>สถานะ</th>
                          <th style={{ width: 120 }}>เพิ่มเติม</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((value, index) => {
                          return (
                            <tr
                              key={index}
                              style={{
                                backgroundColor: value.checked
                                  ? "#e8eaed "
                                  : "",
                              }}
                            >
                              <td
                                style={{
                                  width: 30,
                                  backgroundColor: value.checked
                                    ? "#e8eaed "
                                    : "",
                                }}
                                className="sticky-col"
                              >
                                <input
                                  type="checkbox"
                                  checked={value.checked || ""}
                                  onChange={(e) => {
                                    let tmp = [...data];
                                    data.forEach((vl, idx) => {
                                      if (vl.id === value.id) {
                                        tmp[idx].checked = !tmp[idx].checked;
                                        setdata(tmp);
                                      }
                                    });
                                  }}
                                />
                              </td>
                              <td
                                style={{
                                  backgroundColor: value.checked
                                    ? "#e8eaed "
                                    : "",
                                }}
                                className="sticky-col"
                              >
                                <div
                                  className="thumbnail cursor-img"
                                  data-toggle="modal"
                                  data-target=".show-img"
                                  onClick={() => {
                                    setimg(
                                      value.picture === ""
                                        ? "/images/no-img.jpg"
                                        : `${img_url}${value.picture}`
                                    );
                                  }}
                                >
                                  <img
                                    alt=""
                                    className="lazy img-responsive"
                                    src={
                                      value.picture === ""
                                        ? "/images/no-img.jpg"
                                        : `${img_url}${value.picture}`
                                    }
                                  />
                                </div>
                              </td>
                              <td
                                style={{
                                  backgroundColor: value.checked
                                    ? "#e8eaed "
                                    : "",
                                }}
                                className="sticky-col"
                              >
                                {value.restaurant_id}
                              </td>
                              <td
                                style={{
                                  backgroundColor: value.checked
                                    ? "#e8eaed "
                                    : "",
                                }}
                                className="sticky-col"
                              >
                                <Link
                                  to={`/restaurant/detail/${value.id}/history`}
                                >
                                  <span>{value.name_th}</span>
                                </Link>
                              </td>
                              <td>{value.location.address}</td>
                              <td>{value.location.sub_district.name_th}</td>
                              <td>{value.location.district.name_th}</td>
                              <td>{value.location.province.name_th}</td>
                              <td>{value.location.sub_district.zip_code}</td>
                              <td>{value.phone_numbers}</td>
                              <td>{value.email}</td>
                              <td>{value.area.name_th}</td>
                              <td className="text-success dt-cradit">
                                ฿{value.credit.toLocaleString()}
                                <button
                                  data-toggle="modal"
                                  data-target={`.credit-add`}
                                  className="btn btn-outline-primary btn-sm mb-1"
                                  onClick={() => {
                                    setrider_id(value.id);
                                    setadd_name(value.name_th);
                                    setcredit_amount(1);
                                    setnote("");
                                    setbank("");
                                  }}
                                >
                                  เติมเครดิต
                                </button>
                              </td>
                              {/* <td>
                                <Link
                                  // tabIndex="0"
                                  className="btn btn-outline-primary mb-1"
                                  // target="_blank"
                                  to={`/restaurant/detail/${value.id}/sale`}
                                >
                                  ฿{0}
                                </Link>
                              </td> */}
                              <td>
                                <Link
                                  // tabIndex="0"
                                  className="btn btn-outline-primary mb-1"
                                  // target="_blank"
                                  to={`/restaurant/detail/${value.id}/menu`}
                                >
                                  {value.menu_count}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  // tabIndex="0"
                                  className="btn btn-outline-primary mb-1"
                                  // target="_blank"
                                  to={`/restaurant/detail/${value.id}/rate`}
                                >
                                  {value.user_rating.rating}
                                </Link>
                              </td>
                              <td>
                                {value.categories.map((v, idx) => {
                                  let comma = "";
                                  if (idx !== value.categories.length - 1)
                                    comma = ", ";
                                  return (
                                    <span key={idx}>{v.title + comma}</span>
                                  );
                                })}
                              </td>
                              <td>
                                {moment(value.register_date).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              <td>
                                {(() => {
                                  switch (value.status) {
                                    case 0:
                                      return (
                                        <div className="badge badge-warning">
                                          ระงับ
                                        </div>
                                      );
                                    // break;
                                    case 1:
                                      return (
                                        <div className="badge badge-success">
                                          ใช้งาน
                                        </div>
                                      );

                                    // break;
                                    case 2:
                                      return (
                                        <div className="badge badge-danger">
                                          ไม่อนุมัติ
                                        </div>
                                      );
                                    // break;
                                    case 3:
                                      return (
                                        <div className="badge badge-primary">
                                          รออนุมัติ
                                        </div>
                                      );
                                    // break;

                                    default:
                                      break;
                                  }
                                })()}
                              </td>
                              <td>
                                <Link
                                  // tabIndex="0"
                                  className="btn btn-outline-primary mb-1"
                                  // target="_blank"
                                  to={`/restaurant/detail/${value.id}/history`}
                                >
                                  ประวัติ
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </LoadingMask>

              <div className="mt-2" style={{ float: "right" }}>
                <ReactPaginate
                  previousLabel={"← Previous"}
                  nextLabel={"Next →"}
                  breakLabel={<span className="gap">...</span>}
                  pageCount={total_page}
                  onPageChange={(e) => setcurrent_page(e.selected + 1)}
                  forcePage={current_page - 1}
                  containerClassName={"pagination"}
                  previousLinkClassName={"previous_page"}
                  nextLinkClassName={"next_page"}
                  disabledClassName={"disabled"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="showModdal" className="modal  show-img" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>รูปภาพ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <img
                alt=""
                src={img ? img : ""}
                style={{ maxWidth: 600, display: "block", margin: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div id="showModdal" className={`modal  credit-add`} role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>เติมเครดิต</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="add_name">ร้านอาหาร</label>
                </div>
                <div className="col-lg-5">
                  <input
                    id="add_name"
                    className="form-control"
                    name="add_name"
                    type="text"
                    value={add_name}
                    onChange={(e) => setadd_name(e.target.value)}
                    readOnly
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="bank">ธนาคาร</label>
                </div>
                <div className="col-lg-5">
                  <Select
                    value={bank}
                    onChange={(e) => {
                      setbank(e);
                    }}
                    options={bank_option}
                    placeholder={"ธนาคาร.."}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="credit_amount">จำนวนเงิน(บาท)</label>
                </div>
                <div className="col-lg-5">
                  <input
                    id="credit_amount"
                    className="form-control"
                    name="credit_amount"
                    type="number"
                    placeholder="บาท"
                    onChange={(e) => {
                      if (e.target.value > 0) {
                        setcredit_amount(parseInt(e.target.value));
                      }
                    }}
                    value={credit_amount}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="name">วันที่</label>
                </div>
                <div className="col-lg-5">
                  <div className="input-group input-daterange d-flex align-items-center">
                    <DayPickerInput
                      value={new Date()}
                      placeholder={"วัน/เดือน/ปี"}
                      style={{ width: "100%" }}
                      inputProps={{
                        style: {
                          width: "100%",
                          padding: 5,
                        },
                        disabled: true,
                      }}
                      format="L"
                      formatDate={formatDate}
                      parseDate={parseDate}
                      dayPickerProps={{
                        locale: "th",
                        localeUtils: MomentLocaleUtils,
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="name">หมายเหตุ</label>
                </div>
                <div className="col-lg-5">
                  <input
                    id="name"
                    className="form-control"
                    name="name"
                    type="text"
                    value={note}
                    onChange={(e) => setnote(e.target.value)}
                  />
                </div>
              </div>

              <div className="row mr-2 mb-4">
                <div className="col-12">
                  <div className="row">
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-outline-primary mr-2 ml-auto "
                    >
                      ยกเลิก
                    </button>
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-primary  mr-2 "
                      onClick={() => {
                        addCredit(rider_id);
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
